const path = 'src/sites/homestox/views';
const path2 = 'src/views';

module.exports = [
  {
    path: 'company',
    component: `${path}/CompanyHomePage/page.tsx`
  },
  {
    path: 'company/about',
    component: `${path}/CompanyAboutPage/page.tsx`
  },
  {
    path: 'company/services',
    component: `${path}/CompanyServicesPage/page.tsx`
  },
  {
    path: 'company/plans',
    component: `${path}/CompanyCommissionPlansPage/page.tsx`
  },
  {
    path: '/',
    component: `${path2}/HomePage/homestox/IndexPage.tsx`
  },
  {
    path: 'about-us',
    component: `${path2}/AboutUsPage/homestox/AboutUsPage.tsx`
  },
  {
    path: 'sell',
    component: `${path2}/SellPage/homestox/page.tsx`
  },
  {
    path: 'search',
    component: `${path2}/SearchPage/homestox/page.tsx`
  }
];
