import loadable from '@loadable/component';

// export const SellPage = loadable(() => import('../../src/views/SellPage/homestox'));
export const Header = loadable(() => import('../../src/components/Header/homestox'));
export const Logo = loadable(() => import('../../src/components/Logo/homestox'));
export const Footer = loadable(() => import('../../src/components/Footer/homestox'));
export const PrivacyContent = loadable(() => import('../../src/views/PrivacyPage/PrivacyContent'));
export const TermsContent = loadable(() => import('../../src/views/PrivacyPage/TermsContent'));
// export const AboutUsPage = loadable(() => import('../../src/views/AboutUsPage/homestox'));
// export const IndexPage = loadable(() => import('../../src/views/HomePage/homestox/IndexPage'));
