const path = 'src/sites/homestox/views';
const path2 = 'src/views';

module.exports = [
  {
    path: '/',
    component: `${path2}/HomePage/IndexPage.tsx`
  },
  {
    path: 'about-us',
    component: `${path2}/AboutUsPage/AboutUsPage.tsx`
  },
  {
    path: 'sell',
    component: `${path2}/SellPage/SellPage.tsx`
  },
  {
    path: 'search',
    component: `${path2}/SearchPage/SearchPage.tsx`
  }
];