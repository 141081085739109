/* eslint-disable no-console */
const dayjs = require('dayjs');

function mapListing(listing, cnt) {
  if (!listing.id) {
    console.error(`Listing position: ${cnt}`);
    console.error(listing);
    throw Error(`mapListing: listing.id field should not be ${listing.id}`);
  }
  let listingAgent = {};
  if (listing.listing_agent) {
    listingAgent = {
      name: listing.listing_agent.name || '',
      officePhone: listing.listing_agent.office_phone || '',
      mobilePhone: listing.listing_agent.mobile_phone || '',
      email: listing.listing_agent.email || '',
      agency: listing.listing_office ? listing.listing_office.name : ''
    };
  }
  // if (
  //   typeof listing.images === 'undefined' ||
  //   !Array.isArray(listing.images) ||
  //   listing.images.length < 1
  // ) {
  //   throw new Error(`Listing ${listing.id} contains no images`);
  // }

  return {
    images: Array.isArray(listing.images) ? listing.images : [],
    id: listing.id,
    mlsId: listing.listing_mls_id,
    description: listing.description || '',
    status: listing.mls_status || '',
    price: listing.current_price || listing.original_price || 0,
    zip: listing.zip || '',
    state: listing.state || '',
    city: listing.city || '',
    county: listing.county || '',
    neighborhood: listing.neighborhood || '',
    address1: listing.address1 || '',
    address2: listing.address2 || '',
    googleAddress: listing.google_address || '',
    latitude: listing.latitude || null,
    longitude: listing.longitude || null,
    propertyType: listing.listing_type_hr || '',
    daysOnMarket: listing.days_on_market || null,
    timeOnMarket: listing.publish_date
      ? dayjs().diff(dayjs(listing.publish_date * 1000), 'days')
      : null,
    listedBy: listing.listed_by || '',
    listingAgent,
    schools: !Array.isArray(listing.schools)
      ? {
          elementary: listing.schools.elementary_school,
          middleOrJunior: listing.schools.middle_or_junior_school,
          high: listing.schools.high_school
        }
      : null,
    propertyDetails: {
      beds: listing.property_details.beds,
      baths: listing.property_details.baths,
      bathsFull: listing.property_details.bath_full,
      bathsHalf: listing.property_details.bath_half,
      sqft: listing.property_details.sqft || '',
      yearBuilt: listing.property_details.year_built,
      lotSize: listing.property_details.lot_dimensions || '',
      heat: JSON.stringify(listing.property_details.heat) || '',
      cool: JSON.stringify(listing.property_details.cool) || '',
      appliances: JSON.stringify(listing.property_details.appliances) || '',
      equipment: JSON.stringify(listing.property_details.equipment) || '',
      exteriorFeatures: JSON.stringify(listing.property_details.exterior_features) || '',
      parkingFeatures: JSON.stringify(listing.property_details.parking_features) || '',
      parkingSpaces: listing.property_details.parking_spaces,
      garageSpaces: listing.property_details.garage_spaces,
      garageType: listing.property_details.garage_type || '',
      hoaIncludes: JSON.stringify(listing.property_details.hoa_includes) || '',
      petsAllowed: JSON.stringify(listing.property_details.pets_allowed) || '',
      utilities: {
        sewer: JSON.stringify(listing.property_details.utilities.sewer) || '',
        water: JSON.stringify(listing.property_details.utilities.water) || ''
      }
    },
    propertyTaxes: Number(listing.property_taxes) || 0,
    homeInsurance: Number(listing.home_insurance) || 0,
    hoa: Number(listing.hoa) || 0,
    url: `${listing.id}/`
  };
}

exports.mapListing = mapListing;

exports.mapListingCardDetails = function mapListingCardDetails(listing) {
  const id = listing.listing_id;
  return {
    id,
    favorite: false,
    label: '',
    status: listing.status,
    price: listing.current_price || listing.original_price,
    images: listing.images,
    state: listing.state,
    city: listing.city,
    county: listing.county,
    neighborhood: listing.neighborhood,
    address1: listing.address1,
    address2: String(listing.address2),
    propertyType: listing.property_type,
    beds: listing.beds,
    baths: listing.baths,
    sqft: listing.sqft,
    daysOnMarket: listing.days_on_market,
    url: `${id}/`
  };
};

exports.mapListings = function mapListings(listings) {
  if (!Array.isArray(listings)) {
    // console.error(listings.toString());
    throw Error(`getAllListings response have to be an array instead of ${typeof listings}`);
  }
  return listings.map(mapListing);
};

exports.mapFeaturedListings = function mapFeaturedListings(listings) {
  if (!Array.isArray(listings)) {
    console.error(listings.toString());
    throw Error(`mapFeaturedListings: Response have to be an array instead of ${typeof data}`);
  }
  return listings.map(listing => {
    if (!listing.id) {
      throw Error(
        `mapFeaturedListings: Response _id field should not be instead of ${listing._id}`
      );
    }

    return {
      images: listing.images || [],
      id: listing.id,
      description: listing.description,
      status: listing.status,
      price: listing.current_price,
      zip: listing.zip,
      state: listing.state,
      city: listing.city,
      county: listing.county,
      address1: listing.address1,
      address2: String(listing.address2),
      neighborhood: listing.neighborhood || '',
      beds: listing.property_details.beds,
      baths: listing.property_details.baths,
      sqft: listing.property_details.sqft,
      propertyType: listing.property_type,
      daysOnMarket: listing.days_on_market,
      url: `${listing.id}/`
    };
  });
};

exports.mapDeletedListings = function mapDeletedListings(deletedListings) {
  if (!deletedListings) return [];
  if (!Array.isArray(deletedListings)) {
    console.error(deletedListings.toString());
    throw Error(
      `mapDeletedListings() parameter has to be an array instead of ${typeof deletedListings}`
    );
  }
  return deletedListings.map(({ id }) => {
    if (!id) throw new Error('mapDeletedListings() listing id should not be emplty');
    return {
      id
    };
  });
};
