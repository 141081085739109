import { info } from 'sitedata';
import { formatCurrency } from './rifmFormatters';



const DISCOUNT_COEFFICIENT = info.DISCOUNT_COEFFICIENT || 50;

// returns city, state, postal code
export const getAddress = ({ city, state, zip }) => {
  return `${[city, state].filter(Boolean).join(', ')} ${zip || ''}`;
};

export const getListingUrl = ({ id, address }) => {
  return `/listing/${id}/`;
}

export const calculateDiscount = (price) => {
  if(!price) return '';
  const result = price * 0.025 * DISCOUNT_COEFFICIENT/100;
  return formatCurrency(Math.round(result/100)*100);
}