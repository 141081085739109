const path = 'src/sites/homestox/views';
const path2 = 'src/views';

module.exports = [
  {
    path: '/',
    component: `${path2}/HomePage/iprice/IndexPage.tsx`
  },
  {
    path: 'about-us',
    component: `${path2}/AboutUsPage/iprice/AboutUsPage.tsx`
  },
  {
    path: 'sell',
    component: `${path2}/SellPage/iprice/FlatRatePage.tsx`
  },
  {
    path: 'search',
    component: `${path2}/SearchPage/iprice/page.tsx`
  }
];
