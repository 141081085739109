import { fetch } from 'apis';
import { urlEncodeBody } from 'utils';
import { getStorageObject, clearStorage } from 'utils/localStorage';

const formatLoginData = (response: any): LoginData => ({
  authData: {
    accessToken: response.authData.access_token,
    refreshToken: response.authData.refresh_token,
    expiresIn: response.authData.expires_in
  },
  profile: {
    firstName: response.profile.first_name,
    lastName: response.profile.last_name,
    email: response.profile.email,
    phone: response.profile.phone
  },
  savedItems: {
    savedListings: response.saved_listings,
    savedSearches: response.saved_searches
  }
});

export type LoginPayload = {
  email: string;
  password: string;
};

export async function login(payload: LoginPayload): Promise<LoginData> {
  const response = await fetch('/broker_site/login', {
    method: 'POST',
    body: urlEncodeBody(payload)
  });

  const data = await response.json();

  return formatLoginData(data);
}

export async function loginGoogle(token: string): Promise<LoginData> {
  // const response = await fetch('/auth/google', {
  const response = await fetch('/broker_site/google', {
    method: 'POST',
    body: urlEncodeBody({ google_token: token })
  });

  const data = await response.json();

  return formatLoginData(data);
}

export async function loginFacebook(token: string): Promise<LoginData> {
  // const response = await fetch('/auth/facebook', {
  const response = await fetch('/broker_site/facebook', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ access_token: token })
  });

  const data = await response.json();

  return formatLoginData(data);
}

export async function logout() {
  const authData = getStorageObject('authData');
  if (!authData?.refreshToken) {
    clearStorage();
    throw Error('No token');
  }

  await fetch('/broker_site/logout', {
    method: 'POST',
    body: urlEncodeBody({ refresh_token: authData!.refreshToken })
  });
}

type RegisterPayload = {
  email: string;
  password: string;
};

export async function register(payload: RegisterPayload) {
  await fetch('/broker_site/register', {
    method: 'POST',
    body: urlEncodeBody(payload)
  });
}

export async function confirmRegistration(token: string): Promise<LoginData> {
  const response = await fetch(`/broker_site/confirm_account/${token}`);
  const data = await response.json();

  return formatLoginData(data);
}

type ForgotPasswordPayload = {
  email: string;
};

export async function forgotPassword(payload: ForgotPasswordPayload) {
  const response = await fetch('/broker_site/forgot_password', {
    method: 'POST',
    body: urlEncodeBody(payload)
  });

  const data = await response.json();

  return data;
}

type ResetPasswordPayload = {
  key: string;
  password: string;
};

export async function resetPassword(payload: ResetPasswordPayload) {
  const response = await fetch('/broker_site/reset_password', {
    method: 'POST',
    body: urlEncodeBody(payload)
  });
  const { status } = response;

  if (status !== 200) throw Error('Resetting password failed.');
}
