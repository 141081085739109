import loadable from '@loadable/component';

// export const SellPage = loadable(() => import('../../src/views/SellPage/iprice'));
export const Header = loadable(() => import('../../src/components/Header/iprice'));
export const Logo = loadable(() => import('../../src/components/Logo/iprice'));
export const Footer = loadable(() => import('../../src/components/Footer/iprice'));
export const FeeSection = loadable(() => import('../../src/views/HomePage/iprice/FeeSection'));
export const PrivacyContent = loadable(() => import('../../src/views/PrivacyPage/iprice/PrivacyContent'));
export const TermsContent = loadable(() =>import('../../src/views/PrivacyPage/iprice/TermsContent'));
// export const AboutUsPage = loadable(() => import('../../src/views/AboutUsPage/iprice'));
// export const IndexPage = loadable(() => import('../../src/views/HomePage/iprice'));
